import request from "@/utils/request"
/**
 * 授权登录 `http://account.300624.cn/oauth/authorize?response_type=id_token&client_id=${process.env.VUE_APP_WCW_CLIENT_ID}&scope=openid%20profile&state=123546745&redirect_uri=${process.env.VUE_APP_WCW_PATH}`
 */
// 判断是否已登录
export const checkLogin = (data) => {
  return request.get("/oauth/authorize", { params: data })
}
// 获取退出登录接口URL
export const getLoginOut = (data) => {
  return request.get("/oauth/logout", { params: data })
}
// 依次调用登录接口
export const loginOut = (url, data) => {
  return request.get(url, { params: data })
}
/**
 * 顶部菜单
 */
// 获取登录用户信息
export const getUserInfo = () => {
  return request.get("/api/v1/common/users")
}
// 最近访问列表
export const lastestList = () => {
  return request.get("/api/v1/lastest/application/list")
}
// 系统导航应用
export const defaultSysList = () => {
  return request.get("/api/v1/index/system/list")
}
// 我的应用列表
export const applicationList = () => {
  return request.get("/api/v1/application/list")
}
// 业务系统列表
export const getSystemList = (data) => {
  return request.get("/api/v1/system/list", { params: data })
}

// 最近访问添加
// /api/v1/latest/application/add
export const lastestAdd = (data) => {
  return request.post("/api/v1/latest/application/add", data)
}

// 最近访问添加
// /api/v1/latest/application/add
export const latestAdd = (data) => {
  return request.post("/api/v1/latest/application/add", data)
}

/**
 * 通知推送
 */
export const notifyPush = (client_id) => {
  if (client_id) {
    return request.get(`/api/v1/user-notifications/client/${client_id}/latest`)
  }
}

// 获取退出登录接口URL
export const getUserLoginOut = (data) => {
  return request.get("/oauth/user/logout", { params: data })
}

// 根据code获取token
export const getSysTokenByCode = (code, baseURL) => {
  return request.post("/api/oauth/token", { code: code, baseURL: baseURL })
}

// 获取已注册的微应用列表
export const getRegisterApps = () => {
  return request.get("/api/v1/clients")
}
