import { isArrayEle } from "@/utils"

const VUE_APP_ACCOUNT_BASE_API = process.env.VUE_APP_ACCOUNT_BASE_API

// id_token认证方案，401或退出登录跳转地址（注意：code认证方案，退出登录跳转地址同为getLoginUrl）
export function getLoginUrl() {
  let url = ""
  // 重定向到登录页面
  const redirectInfo = getRedirectInfo()
  const VUE_APP_WCW_PATH = redirectInfo.path
  url = `${VUE_APP_ACCOUNT_BASE_API}/oauth/user/logout?redirect_uri=${encodeURIComponent(
    VUE_APP_WCW_PATH
  )}`
  return url
}

// code认证方案，401跳转地址（注意：code认证方案，退出登录跳转地址为getLoginUrl）
export function getCodeAuthLoginUrl(type) {
  let url = ""

  // 重定向到登录页面
  const redirectInfo = getRedirectInfo()
  let clientId = redirectInfo.clientId
  let VUE_APP_WCW_PATH = redirectInfo.path

  if (type === "request401") {
    // const url = decodeURIComponent(window.location.href)
    // if (url.includes('/wgp')) {
    //     // wgp 401 特殊处理
    //     if (
    //         requestApiUrl === '/api/v1/latest/application/add'
    //     ) {
    //         // 解决wgp登入后，删除wcw cookie后，切换wgp菜单后反复跳转的问题
    //         clientId = getClientId('wcw')
    //         VUE_APP_WCW_PATH = getOrigin('wcw')
    //     } else {
    //         clientId = getClientId('wgp')
    //         VUE_APP_WCW_PATH = getOrigin('wgp')
    //     }
    // } else {
    //     clientId = getClientId('wcw')
    //     VUE_APP_WCW_PATH = getOrigin('wcw')
    // }
    clientId = getClientId("wcw")
    VUE_APP_WCW_PATH = getOrigin("wcw")
  }

  url = `${VUE_APP_ACCOUNT_BASE_API}/oauth/authorize?response_type=code&client_id=${clientId}&scope=openid%20profile&state=123546745&redirect_uri=${encodeURIComponent(
    VUE_APP_WCW_PATH
  )}`
  return url
}

export function getClientId(sys = "wcw") {
  let envJson = {
    devClientId: 2,
    betaClientId: 1,
    proClientId: 1
  }
  if (sys === "wcw") {
    envJson = {
      devClientId: 2,
      betaClientId: 1,
      proClientId: 1
    }
  }
  if (sys === "pms") {
    envJson = {
      devClientId: 4,
      betaClientId: 2,
      proClientId: 2
    }
  }
  if (sys === "crm") {
    // 新增子系统
    envJson = {
      devClientId: 6,
      betaClientId: 6,
      proClientId: 3
    }
  }
  if (sys === "tms") {
    // 新增子系统
    envJson = {
      devClientId: 22,
      betaClientId: 18,
      proClientId: 6
    }
  }
  if (sys === "mgs") {
    // 新增子系统
    envJson = {
      devClientId: 24,
      betaClientId: 19,
      proClientId: 7
    }
  }
  if (sys === "ums") {
    // 新增子系统
    envJson = {
      devClientId: 25,
      betaClientId: 20,
      proClientId: 8
    }
  }
  if (sys === "cms40") {
    // 新增子系统
    envJson = {
      devClientId: 23,
      betaClientId: 23,
      proClientId: 10
    }
  }
  if (sys === "aims") {
    // 新增子系统
    envJson = {
      devClientId: 29,
      betaClientId: 25,
      proClientId: 15
    }
  }
  if (sys === "datamonitor") {
    // 新增子系统
    envJson = {
      devClientId: 30,
      betaClientId: 30,
      proClientId: 25
    }
  }
  if (sys === "rms") {
    // 新增子系统
    envJson = {
      devClientId: 55,
      betaClientId: 55,
      proClientId: 55
    }
  }
  if (sys === "wcc") {
    // 新增子系统
    envJson = {
      devClientId: 65,
      betaClientId: 65,
      proClientId: 65
    }
  }
  if (sys === "csms") {
    // 新增子系统
    envJson = {
      devClientId: 75,
      betaClientId: 75,
      proClientId: 75
    }
  }
  if (sys === "vlms") {
    // 新增子系统
    envJson = {
      devClientId: 175,
      betaClientId: 175,
      proClientId: 175
    }
  }
  if (sys === "wgp") {
    // 新增子系统
    envJson = {
      devClientId: 185,
      betaClientId: 185,
      proClientId: 185
    }
  }
  if (sys === "fms") {
    // 新增子系统
    envJson = {
      devClientId: 186,
      betaClientId: 209,
      proClientId: 235
    }
  }
  if (sys === "fsm") {
    // 新增子系统
    envJson = {
      devClientId: 246,
      betaClientId: 246,
      proClientId: 255
    }
  }
  if (sys === "fmp") {
    // 新增子系统
    envJson = {
      devClientId: 247,
      betaClientId: 247,
      proClientId: 265
    }
  }
  if (sys === "fbm") {
    // 新增子系统
    envJson = {
      devClientId: 248,
      betaClientId: 248,
      proClientId: 275
    }
  }
  if (sys === "ffm") {
    // 新增子系统
    envJson = {
      devClientId: 249,
      betaClientId: 249,
      proClientId: 285
    }
  }
  if (sys === "sms") {
    // 新增子系统
    envJson = {
      devClientId: 206,
      betaClientId: 207,
      proClientId: 215
    }
  }
  if (sys === "pmis") {
    // 新增子系统
    envJson = {
      devClientId: 250,
      betaClientId: 292,
      proClientId: 315
    }
  }
  if (sys === "fom") {
    // 新增子系统
    envJson = {
      devClientId: 291,
      betaClientId: 291,
      proClientId: 305
    }
  }
  if (sys === "prms") {
    // 新增子系统
    envJson = {
      devClientId: 208,
      betaClientId: 210,
      proClientId: 225
    }
  }
  if (sys === "cdp") {
    // 新增子系统
    envJson = {
      devClientId: 325,
      betaClientId: 325,
      proClientId: 325
    }
  }
  if (sys === "osm") {
    // 新增子系统
    envJson = {
      devClientId: 336,
      betaClientId: 336,
      proClientId: 345
    }
  }
  if (sys === "frm") {
    // 新增子系统
    envJson = {
      devClientId: 337,
      betaClientId: 337,
      proClientId: 355
    }
  }
  if (sys === "etm") {
    // 新增子系统
    envJson = {
      devClientId: 338,
      betaClientId: 338,
      proClientId: 375
    }
  }
  if (sys === "uvm") {
    // 新增子系统
    envJson = {
      devClientId: 340,
      betaClientId: 340,
      proClientId: 385
    }
  }
  if (sys === "aidams") {
    // 新增子系统
    envJson = {
      devClientId: 395,
      betaClientId: 395,
      proClientId: 395
    }
  }
  if (sys === "adms") {
    // 新增子系统
    envJson = {
      devClientId: 400,
      betaClientId: 399,
      proClientId: 435
    }
  }
  if (sys === "ftm") {
    // 新增子系统
    envJson = {
      devClientId: 398,
      betaClientId: 397,
      proClientId: 415
    }
  }
  if (sys === "ims") {
    // 新增子系统
    envJson = {
      devClientId: 401,
      betaClientId: 400,
      proClientId: 445
    }
  }
  let res = ""
  const { host } = window.location
  if (host === "dev-wcw.300624.cn") {
    res = envJson.devClientId
  } else if (host === "beta-wcw.300624.cn") {
    res = envJson.betaClientId
  } else if (host === "workspace.300624.cn") {
    res = envJson.proClientId
  }
  return res
}

export function getOrigin(sysName = "wcw") {
  let res = ""
  const { protocol, host } = window.location
  // 默认兴云工作台
  let envJson = {
    devClientUrl: `${protocol}//dev-wcw.300624.cn/wcw/`,
    betaClientUrl: `${protocol}//beta-wcw.300624.cn/wcw/`,
    proClientUrl: `${protocol}//workspace.300624.cn/wcw/`
  }
  if (sysName === "wcw") {
    envJson = {
      devClientUrl: `${protocol}//dev-wcw.300624.cn/wcw/`,
      betaClientUrl: `${protocol}//beta-wcw.300624.cn/wcw/`,
      proClientUrl: `${protocol}//workspace.300624.cn/wcw/`
    }
  }
  if (sysName === "pms") {
    envJson = {
      devClientUrl: `${protocol}//dev-pms.300624.cn/pms/`,
      betaClientUrl: `${protocol}//beta-pms.300624.cn/pms/`,
      proClientUrl: `${protocol}//pms.300624.cn/pms/`
    }
  }
  if (sysName === "crm") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-crm-admin.300624.cn/crm/`,
      betaClientUrl: `${protocol}//beta-crm-admin.300624.cn/crm/`,
      proClientUrl: `${protocol}//crm-admin.300624.cn/crm/`
    }
  }
  if (sysName === "tms") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-tms.300624.cn/tms/`,
      betaClientUrl: `${protocol}//beta-tms.300624.cn/tms/`,
      proClientUrl: `${protocol}//tms.300624.cn/tms/`
    }
  }
  if (sysName === "mgs") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-mgs-admin.300624.cn/mgs/`,
      betaClientUrl: `${protocol}//beta-mgs-admin.300624.cn/mgs/`,
      proClientUrl: `${protocol}//mgs-admin.300624.cn/mgs/`
    }
  }
  if (sysName === "ums") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-ums.300624.cn/ums/`,
      betaClientUrl: `${protocol}//beta-ums.300624.cn/ums/`,
      proClientUrl: `${protocol}//ums.300624.cn/ums/`
    }
  }
  if (sysName === "cms40") {
    // 新增子系统
    envJson = {
      // devClientUrl: `${protocol}//dev-cms40.300624.cn/cms40/`,
      // betaClientUrl: `${protocol}//beta-cms40.300624.cn/cms40/`,
      // proClientUrl: `${protocol}//cms40.300624.cn/cms40/`
      devClientUrl: `${protocol}//dev-cms40.300624.cn/cms40/`,
      betaClientUrl: `${protocol}//beta-cms40.wondershare.cn/cms40/`,
      proClientUrl: `${protocol}//cms40.wondershare.cn/cms40/`
      // http://beta-cms40.wondershare.cn/ http://cms40.wondershare.cn/
    }
  }
  if (sysName === "aims") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-aims.300624.cn/aims/`,
      betaClientUrl: `${protocol}//beta-aims.300624.cn/aims/`,
      proClientUrl: `${protocol}//aims.300624.cn/aims/`
    }
  }
  if (sysName === "datamonitor") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//beta-datamonitor.300624.cn/datamonitor/`,
      betaClientUrl: `${protocol}//beta-datamonitor.300624.cn/datamonitor/`,
      proClientUrl: `${protocol}//datamonitor.300624.cn/datamonitor/`
    }
  }
  if (sysName === "rms") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-rms.300624.cn/rms/`,
      betaClientUrl: `${protocol}//beta-rms.300624.cn/rms/`,
      proClientUrl: `${protocol}//rms.300624.cn/rms/`
    }
  }
  if (sysName === "wcc") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-cc-admin.300624.cn/wcc/`,
      betaClientUrl: `${protocol}//beta-cc-admin.300624.cn/wcc/`,
      proClientUrl: `${protocol}//cc-admin.300624.cn/wcc/`
    }
  }
  if (sysName === "csms") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-drive-admin.300624.cn/csms/`,
      betaClientUrl: `${protocol}//beta-drive-admin.300624.cn/csms/`,
      proClientUrl: `${protocol}//drive-admin.300624.cn/csms/`
    }
  }
  if (sysName === "vlms") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-vlms.300624.cn/vlms/`,
      betaClientUrl: `${protocol}//beta-vlms.300624.cn/vlms/`,
      proClientUrl: `${protocol}//vlms.300624.cn/vlms/`
    }
  }
  if (sysName === "wgp") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-wgp-admin.300624.cn/wgp/`,
      betaClientUrl: `${protocol}//beta-wgp-admin.300624.cn/wgp/`,
      proClientUrl: `${protocol}//wgp-admin.300624.cn/wgp/`
    }
  }
  if (sysName === "fms") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-fms.300624.cn/fms/`,
      betaClientUrl: `${protocol}//beta-fms.300624.cn/fms/`,
      proClientUrl: `${protocol}//fms.300624.cn/fms/`
    }
  }
  if (sysName === "fsm") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-fsm.300624.cn/fsm/`,
      betaClientUrl: `${protocol}//beta-fsm.300624.cn/fsm/`,
      proClientUrl: `${protocol}//fsm.300624.cn/fsm/`
    }
  }
  if (sysName === "fmp") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-fmp.300624.cn/fmp/`,
      betaClientUrl: `${protocol}//beta-fmp.300624.cn/fmp/`,
      proClientUrl: `${protocol}//fmp.300624.cn/fmp/`
    }
  }
  if (sysName === "fbm") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-fbm.300624.cn/fbm/`,
      betaClientUrl: `${protocol}//beta-fbm.300624.cn/fbm/`,
      proClientUrl: `${protocol}//fbm.300624.cn/fbm/`
    }
  }
  if (sysName === "ffm") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-ffm.300624.cn/ffm/`,
      betaClientUrl: `${protocol}//beta-ffm.300624.cn/ffm/`,
      proClientUrl: `${protocol}//ffm.300624.cn/ffm/`
    }
  }
  if (sysName === "sms") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-sms.300624.cn/sms/`,
      betaClientUrl: `${protocol}//beta-sms.300624.cn/sms/`,
      proClientUrl: `${protocol}//smst.300624.cn/sms/`
    }
  }
  if (sysName === "pmis") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-pmis.300624.cn/pmis/`,
      betaClientUrl: `${protocol}//beta-pmis.300624.cn/pmis/`,
      proClientUrl: `${protocol}//pmis.300624.cn/pmis/`
    }
  }
  if (sysName === "fom") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-fom.300624.cn/fom/`,
      betaClientUrl: `${protocol}//beta-fom.300624.cn/fom/`,
      proClientUrl: `${protocol}//fom.300624.cn/fom/`
    }
  }
  if (sysName === "prms") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-prms.300624.cn/prms/`,
      betaClientUrl: `${protocol}//beta-prms.300624.cn/prms/`,
      proClientUrl: `${protocol}//prms.300624.cn/prms/`
    }
  }
  if (sysName === "cdp") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-cdp.300624.cn/cdp/`,
      betaClientUrl: `${protocol}//beta-cdp.300624.cn/cdp/`,
      proClientUrl: `${protocol}//cdp.300624.cn/cdp/`
    }
  }
  if (sysName === "osm") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-osm.300624.cn/osm/`,
      betaClientUrl: `${protocol}//beta-osm.300624.cn/osm/`,
      proClientUrl: `${protocol}//osm.300624.cn/osm/`
    }
  }
  if (sysName === "frm") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-frm.300624.cn/frm/`,
      betaClientUrl: `${protocol}//beta-frm.300624.cn/frm/`,
      proClientUrl: `${protocol}//frm.300624.cn/frm/`
    }
  }
  if (sysName === "etm") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-etm.300624.cn/etm/`,
      betaClientUrl: `${protocol}//beta-etm.300624.cn/etm/`,
      proClientUrl: `${protocol}//etm.300624.cn/etm/`
    }
  }
  if (sysName === "uvm") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-uvm.300624.cn/uvm/`,
      betaClientUrl: `${protocol}//beta-uvm.300624.cn/uvm/`,
      proClientUrl: `${protocol}//uvm.300624.cn/uvm/`
    }
  }
  if (sysName === "aidams") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-aidams.300624.cn/aidams/`,
      betaClientUrl: `${protocol}//beta-aidams.300624.cn/aidams/`,
      proClientUrl: `${protocol}//aidams.300624.cn/aidams/`
    }
  }
  if (sysName === "adms") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-adms.300624.cn/adms/`,
      betaClientUrl: `${protocol}//beta-adms.300624.cn/adms/`,
      proClientUrl: `${protocol}//adms.300624.cn/adms/`
    }
  }
  if (sysName === "ftm") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-ftm.300624.cn/ftm/`,
      betaClientUrl: `${protocol}//beta-ftm.300624.cn/ftm/`,
      proClientUrl: `${protocol}//ftm.300624.cn/ftm/`
    }
  }
  if (sysName === "ims") {
    // 新增子系统
    envJson = {
      devClientUrl: `${protocol}//dev-ims.300624.cn/ims/`,
      betaClientUrl: `${protocol}//beta-ims.300624.cn/ims/`,
      proClientUrl: `${protocol}//ims.300624.cn/ims/`
    }
  }

  if (host === "dev-wcw.300624.cn") {
    res = envJson.devClientUrl
  } else if (host === "beta-wcw.300624.cn") {
    res = envJson.betaClientUrl
  } else if (host === "workspace.300624.cn") {
    res = envJson.proClientUrl
  }
  return res
}

export function getRedirectInfo() {
  const { pathname, href } = window.location
  let clientId = getClientId("wcw")
  let path = href
  // 去掉code后的参数
  if (path.includes("?code=")) {
    path = path.split("?code=")[0]
  }
  if (path.includes("&code=")) {
    path = path.split("&code=")[0]
  }
  if (pathname.startsWith("/pms")) {
    clientId = getClientId("pms")
    path = href
      .replace("dev-wcw", "dev-pms")
      .replace("beta-wcw", "beta-pms")
      .replace("workspace", "pms")
  }
  if (pathname.startsWith("/crm")) {
    clientId = getClientId("crm")
    path = href
      .replace("dev-wcw", "dev-crm-admin")
      .replace("beta-wcw", "beta-crm-admin")
      .replace("workspace", "crm-admin")
  }
  if (pathname.startsWith("/tms")) {
    clientId = getClientId("tms")
    path = href
      .replace("dev-wcw", "dev-tms")
      .replace("beta-wcw", "beta-tms")
      .replace("workspace", "tms")
  }
  if (pathname.startsWith("/mgs")) {
    clientId = getClientId("mgs")
    path = href
      .replace("dev-wcw", "dev-mgs-admin")
      .replace("beta-wcw", "beta-mgs-admin")
      .replace("workspace", "mgs-admin")
  }
  if (pathname.startsWith("/ums")) {
    clientId = getClientId("ums")
    path = href
      .replace("dev-wcw", "dev-ums")
      .replace("beta-wcw", "beta-ums")
      .replace("workspace", "ums")
      .replace("test-workspace", "test-ums")
  }
  if (pathname.startsWith("/cms40")) {
    clientId = getClientId("cms40")
    path = href
      .replace("dev-wcw", "dev-cms40")
      .replace("beta-wcw", "beta-cms40")
      .replace("workspace", "cms40")
  }
  if (pathname.startsWith("/aims")) {
    clientId = getClientId("aims")
    path = href
      .replace("dev-wcw", "dev-aims")
      .replace("beta-wcw", "beta-aims")
      .replace("workspace", "aims")
  }
  if (pathname.startsWith("/datamonitor")) {
    clientId = getClientId("datamonitor")
    path = href
      .replace("dev-wcw", "beta-datamonitor")
      .replace("beta-wcw", "beta-datamonitor")
      .replace("workspace", "datamonitor")
  }
  if (pathname.startsWith("/rms")) {
    clientId = getClientId("rms")
    path = href
      .replace("dev-wcw", "dev-rms")
      .replace("beta-wcw", "beta-rms")
      .replace("workspace", "rms")
  }
  if (pathname.startsWith("/wcc")) {
    clientId = getClientId("wcc")
    path = href
      .replace("dev-wcw", "dev-cc-admin")
      .replace("beta-wcw", "beta-cc-admin")
      .replace("workspace", "cc-admin")
  }
  if (pathname.startsWith("/csms")) {
    clientId = getClientId("csms")
    path = href
      .replace("dev-wcw", "dev-drive-admin")
      .replace("beta-wcw", "beta-drive-admin")
      .replace("workspace", "drive-admin")
  }
  if (pathname.startsWith("/vlms")) {
    clientId = getClientId("vlms")
    path = href
      .replace("dev-wcw", "dev-vlms")
      .replace("beta-wcw", "beta-vlms")
      .replace("workspace", "vlms")
  }
  if (pathname.startsWith("/etm")) {
    clientId = getClientId("frm")
    path = href
      .replace("dev-wcw", "dev-etm")
      .replace("beta-wcw", "beta-etm")
      .replace("workspace", "etm")
      .replace("test-workspace", "test-etm")
  }
  if (pathname.startsWith("/uvm")) {
    clientId = getClientId("uvm")
    path = href
      .replace("dev-wcw", "dev-uvm")
      .replace("beta-wcw", "beta-uvm")
      .replace("workspace", "uvm")
      .replace("test-workspace", "test-uvm")
  }
  if (pathname.startsWith("/wgp")) {
    clientId = getClientId("wgp")
    path = href
      .replace("dev-wcw", "dev-wgp-admin")
      .replace("beta-wcw", "beta-wgp-admin")
      .replace("workspace", "wgp-admin")
  }
  if (pathname.startsWith("/fms")) {
    clientId = getClientId("fms")
    path = href
      .replace("dev-wcw", "dev-fms")
      .replace("beta-wcw", "beta-fms")
      .replace("workspace", "fms")
      .replace("test-workspace", "test-fms")
  }
  if (pathname.startsWith("/fsm")) {
    clientId = getClientId("fsm")
    path = href
      .replace("dev-wcw", "dev-fsm")
      .replace("beta-wcw", "beta-fsm")
      .replace("workspace", "fsm")
      .replace("test-workspace", "test-fsm")
  }
  if (pathname.startsWith("/fmp")) {
    clientId = getClientId("fmp")
    path = href
      .replace("dev-wcw", "dev-fmp")
      .replace("beta-wcw", "beta-fmp")
      .replace("workspace", "fmp")
      .replace("test-workspace", "test-fmp")
  }
  if (pathname.startsWith("/fbm")) {
    clientId = getClientId("fbm")
    path = href
      .replace("dev-wcw", "dev-fbm")
      .replace("beta-wcw", "beta-fbm")
      .replace("workspace", "fbm")
      .replace("test-workspace", "test-fbm")
  }
  if (pathname.startsWith("/ffm")) {
    clientId = getClientId("ffm")
    path = href
      .replace("dev-wcw", "dev-ffm")
      .replace("beta-wcw", "beta-ffm")
      .replace("workspace", "ffm")
      .replace("test-workspace", "test-ffm")
  }
  if (pathname.startsWith("/sms")) {
    clientId = getClientId("sms")
    path = href
      .replace("dev-wcw", "dev-sms")
      .replace("beta-wcw", "beta-sms")
      .replace("workspace", "smst")
      .replace("test-workspace", "test-sms")
  }
  if (pathname.startsWith("/pmis")) {
    clientId = getClientId("pmis")
    path = href
      .replace("dev-wcw", "dev-pmis")
      .replace("beta-wcw", "beta-pmis")
      .replace("workspace", "pmis")
      .replace("test-workspace", "test-pmis")
  }
  if (pathname.startsWith("/fom")) {
    clientId = getClientId("fom")
    path = href
      .replace("dev-wcw", "dev-fom")
      .replace("beta-wcw", "beta-fom")
      .replace("workspace", "fom")
      .replace("test-workspace", "test-fom")
  }
  if (pathname.startsWith("/prms")) {
    clientId = getClientId("prms")
    path = href
      .replace("dev-wcw", "dev-prms")
      .replace("beta-wcw", "beta-prms")
      .replace("workspace", "prms")
      .replace("test-workspace", "test-prms")
  }
  if (pathname.startsWith("/cdp")) {
    clientId = getClientId("cdp")
    path = href
      .replace("dev-wcw", "dev-cdp")
      .replace("beta-wcw", "beta-cdp")
      .replace("workspace", "cdp")
      .replace("test-workspace", "test-cdp")
  }
  if (pathname.startsWith("/osm")) {
    clientId = getClientId("osm")
    path = href
      .replace("dev-wcw", "dev-osm")
      .replace("beta-wcw", "beta-osm")
      .replace("workspace", "osm")
      .replace("test-workspace", "test-osm")
  }
  if (pathname.startsWith("/frm")) {
    clientId = getClientId("frm")
    path = href
      .replace("dev-wcw", "dev-frm")
      .replace("beta-wcw", "beta-frm")
      .replace("workspace", "frm")
      .replace("test-workspace", "test-frm")
  }
  if (pathname.startsWith("/aidams")) {
    clientId = getClientId("aidams")
    path = href
      .replace("dev-wcw", "dev-aidams")
      .replace("beta-wcw", "beta-aidams")
      .replace("workspace", "aidams")
      .replace("test-workspace", "test-aidams")
  }
  if (pathname.startsWith("/adms")) {
    clientId = getClientId("adms")
    path = href
      .replace("dev-wcw", "dev-adms")
      .replace("beta-wcw", "beta-adms")
      .replace("workspace", "adms")
      .replace("test-workspace", "test-adms")
  }
  if (pathname.startsWith("/ftm")) {
    clientId = getClientId("ftm")
    path = href
      .replace("dev-wcw", "dev-ftm")
      .replace("beta-wcw", "beta-ftm")
      .replace("workspace", "ftm")
      .replace("test-workspace", "test-ftm")
  }
  if (pathname.startsWith("/ims")) {
    clientId = getClientId("ims")
    path = href
      .replace("dev-wcw", "dev-ims")
      .replace("beta-wcw", "beta-ims")
      .replace("workspace", "ims")
      .replace("test-workspace", "test-ims")
  }

  return {
    clientId: clientId,
    path: path
  }
}

export function getCurrentSysIdByPathName() {
  let res = ""
  const pathName = window.location.pathname
  if (pathName.includes("/")) {
    const arr = pathName.split("/")
    if (isArrayEle(arr) && arr.length > 1) {
      res = getClientId(arr[1])
    }
  }
  return res
}

export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf("?") + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}
