const state = {
  mainPopVisible: false,
  currentFeedbackId: "",
  ossBasicInfo: {}
}

const mutations = {
  SET_MAIN_POP_VISIBLE: (state, value) => {
    console.log("SET_MAIN_POP_VISIBLE", value)
    state.mainPopVisible = value
  },
  SET_CURRENT_FEEDBACK_ID: (state, value) => {
    console.log("SET_CURRENT_FEEDBACK_ID", value)
    state.currentFeedbackId = value
  },
  SET_OSS_BASIC_INfO: (state, value) => {
    // eslint-disable-next-line no-prototype-builtins
    console.log("SET_OSS_BASIC_INfO", value)
    state.ossBasicInfo = value
  }
}

const actions = {
  SET_MAIN_POP_VISIBLE({ commit }, value) {
    commit("SET_MAIN_POP_VISIBLE", value)
  },
  SET_CURRENT_FEEDBACK_ID({ commit }, value) {
    commit("SET_CURRENT_FEEDBACK_ID", value)
  },
  SET_OSS_BASIC_INfO({ commit }, value) {
    commit("SET_OSS_BASIC_INfO", value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
