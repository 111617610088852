import Vue from "vue"
import { registerMicroApps, start, setDefaultMountApp } from "qiankun"
import ElementUI from "element-ui"
import locale from "element-ui/lib/locale/lang/zh-CN" // lang i18n
import "element-ui/lib/theme-chalk/index.css"
import "nprogress/nprogress.css"
import { Button, Menu, Submenu, MenuItem, MessageBox } from "element-ui"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import microApps from "./micro-app"
import {
  setToken,
  getToken,
  setCms40AdminToken,
  setCms40WSAdminToken,
  setCurrentDomainToken
} from "@/utils/auth"
import { getClientId, getQueryObject } from "@/utils/getEnv"
import { notifyPush, defaultSysList, getSysTokenByCode } from "@/api"

Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Button)

// set ElementUI lang to EN
Vue.use(ElementUI, { locale })
Vue.prototype.$confirm = MessageBox.confirm
Vue.config.productionTip = false
const instance = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")

// 定义loader方法，loading改变时，将变量赋值给App.vue的data中的isLoading
function loader(loading) {
  if (instance && instance.$children) {
    // instance.$children[0] 是App.vue，此时直接改动App.vue的isLoading
    instance.$children[0].isLoading = loading
  }
}

// 给子应用配置加上loader方法
const apps = microApps.map((item) => {
  return {
    ...item,
    loader
  }
})

async function mainRouterGaurd() {
  console.log("mainRouterGaurd window.location.href", window.location.href)
  console.log(
    "mainRouterGaurd window.location.pathname",
    window.location.pathname
  )
  const url = decodeURIComponent(window.location.href) // url解码
  const accessTokenIndex = url.indexOf("/?access_token=")
  const redirectIndex = url.indexOf("redirect_uri=")
  const codeIndex = url.indexOf("?code=")
  console.log("000 getToken()", getToken())
  console.log("000 decoded url", url)
  store.dispatch("code/setCodeToTokenSuccess", false)
  if (accessTokenIndex !== -1) {
    // access_token认证方案
    const urlArr = url.split("&redirect_uri=")
    const params = getQueryObject(urlArr[0])
    console.log("111 params", params)
    console.log("111 params.access_token", params.access_token)
    if (params.access_token && params.token_type) {
      const url0 = url.split("/?access_token=")[0]
      if (url0.endsWith("/wcw") || url0.endsWith(".300624.cn")) {
        // wcw带token
        setToken(`${params.token_type} ${params.access_token}`)
        setToken(`${params.id_token}`, "wcwIdToken")
        setCurrentDomainToken("id_token", "wcwAuthMode")
        if (url.includes("cms40")) {
          // cms40设置Admin-Token
          setCms40AdminToken(
            `${params.token_type} ${params.access_token}***${params.id_token}`
          )
        }
      } else {
        // 子系统域名带token
        const uArr = url0.split("/")
        console.log("uArr", uArr)
        const appName = uArr[uArr.length - 1]
        setToken(
          `${params.token_type} ${params.access_token}`,
          `${appName}AuthToken`
        )
        setToken(`${params.id_token}`, `${appName}IdToken`)
      }
    }
    console.log("111 getToken()", getToken())
    if (redirectIndex !== -1 && urlArr.length > 1) {
      // 有redirect_uri，还要跳转子系统
      let redirectUri = urlArr[1]
      console.log("111 redirect_uri", redirectUri)
      if (url.includes("cms40")) {
        // cms40跳转地址兼容处理
        const arr = redirectUri.split("/cms40")
        redirectUri = arr[0] + arr[1]
      }
      if (url.includes("crm")) {
        redirectUri = redirectUri
          .replace("dev-wcw", "dev-crm-admin")
          .replace("beta-wcw", "beta-crm-admin")
          .replace("workspace", "crm-admin")
        // window.open(redirectUri, '_blank')
        window.location.href = redirectUri
      } else {
        window.location.href = redirectUri
      }
    } else {
      // 没有redirect_uri，直接进入子系统
      let sysUrl = url.split("?access_token=")[0]
      if (sysUrl.includes("crm")) {
        // crm跳到独立域名新页面
        sysUrl = sysUrl
          .replace("dev-wcw", "dev-crm-admin")
          .replace("beta-wcw", "beta-crm-admin")
          .replace("workspace", "crm-admin")
        // window.open(sysUrl, '_blank')
        window.location.href = sysUrl
      } else {
        window.location.href = sysUrl.endsWith(".300624.cn/")
          ? sysUrl + "wcw"
          : sysUrl
      }
    }
  } else if (codeIndex !== -1) {
    // code认证方案
    const urlArr = url.split("&redirect_uri=")
    const params = getQueryObject(urlArr[0])
    console.log("222 params", params)
    console.log("222 params.code", params.code)
    const { pathname } = window.location
    const appName = pathname.split("/")[1] || "wcw"
    if (params.error && params.error_description && params.state) {
      return alert(`error：${params.error}`)
    }
    if (params.code) {
      if (appName === "wcw") {
        store.dispatch("code/setCodeToTokenSuccess", false)
        try {
          const codeRes = await getSysTokenByCode(params.code)
          if (codeRes && codeRes.code === 0) {
            console.log("main wcw getSysTokenByCode success")
            store.dispatch("code/setCodeToTokenSuccess", true)
          } else {
            store.dispatch("code/setCodeToTokenSuccess", false)
            return
          }
        } catch (err) {
          console.error("getSysTokenByCode err " + err)
          store.dispatch("code/setCodeToTokenSuccess", false)
          return alert(`${appName} getSysTokenByCode error`)
        }

        setCurrentDomainToken("code", "wcwAuthMode")
        if (url.includes("cms40")) {
          // cms40设置Admin-Token
          setCms40AdminToken(`***${params.code}***`, "Admin-Token")
          setCms40WSAdminToken(`***${params.code}***`, "Admin-Token")
        }
      }
    }
    console.log("222 getToken()", getToken())
    if (redirectIndex !== -1 && urlArr.length > 1) {
      // 有redirect_uri，还要跳转子系统
      let redirectUri = urlArr[1]
      console.log("222 redirect_uri", redirectUri)
      if (url.includes("cms40") && url.includes("beta-cms40")) {
        // cms40跳转地址兼容处理
        const arr = redirectUri.split("/cms40")
        redirectUri = arr[0] + arr[1]
      }
      if (url.includes("crm")) {
        redirectUri = redirectUri
          .replace("dev-wcw", "dev-crm-admin")
          .replace("beta-wcw", "beta-crm-admin")
          .replace("workspace", "crm-admin")
        // window.open(redirectUri, '_blank')
        window.location.href = redirectUri
      } else {
        window.location.href = redirectUri
      }
    } else {
      // 没有redirect_uri，直接进入子系统
      let sysUrl = url.split("?code=")[0]
      if (sysUrl.includes("crm")) {
        // crm跳到独立域名新页面
        sysUrl = sysUrl
          .replace("dev-wcw", "dev-crm-admin")
          .replace("beta-wcw", "beta-crm-admin")
          .replace("workspace", "crm-admin")
        // window.open(sysUrl, '_blank')
        window.location.href = sysUrl
      } else {
        // url必须是独立域名的形式（dev-pms.300624.cn/pms），且携带code参数，进入子系统后，子系统对该code进行处理，不能是兴云工作台域名的形式（dev-wcw.300624.cn/pms）
        window.location.href = sysUrl.endsWith(".300624.cn/")
          ? sysUrl + "wcw"
          : url
      }
    }
  } else {
    store.dispatch("code/setCodeToTokenSuccess", true)
  }
  console.log("333 getToken()", getToken())
}

mainRouterGaurd()

registerMicroApps(apps, {
  beforeLoad: async (app) => {
    console.log("beforeLoad", app)
    console.log("beforeLoad", app.name)
  },
  beforeMount: [
    (app) => {
      console.log("beforeMount", app)
      console.log("beforeMount", app.name)
    }
  ],
  afterMount: [
    (app) => {
      let client_id = ""
      // 系统导航
      defaultSysList().then((res) => {
        if (res.code === 0) {
          console.log("app.name", app.name)
          // res.data.forEach(item => {
          //     console.log('item', item)
          //     if (app.name === 'tms') {
          //         client_id = getClientId('tms')
          //     } else if (item.domain === 'http://workspace.300624.cn') {
          //         client_id = getClientId()
          //     } else if (item.domain.includes(app.name)) {
          //         client_id = item.id
          //     }
          // })
          client_id = getClientId(app.name)
          console.log("client_id", client_id)
          if (client_id) {
            notifyPush(client_id).then((res) => {
              if (res.code === 0) {
                if (res.data.length > 0) {
                  const data = res.data
                  data.forEach((item) => {
                    // item.content = item.content.replace(/\n/g, '<br>')
                    ElementUI.Notification({
                      title: item.title,
                      message: `<span>${item.content.slice(
                        0,
                        99
                      )}</span><span style="display: ${
                        item.content.length > 100 ? "" : "none"
                      }">... <span style="color: blue;">查看更多>>></span></span>`,
                      position: "bottom-right",
                      dangerouslyUseHTMLString: true,
                      customClass: "notifyClass",
                      iconClass: "el-icon-message-solid",
                      duration: 0,
                      onClick() {
                        this.close()
                        const path = "/wcw"
                        window.location.href = `/wcw/viewBoard/notifyDetail?id=${item.id}`
                        this.activeIndex = path
                      }
                    })
                  })
                }
              }
            })
            setInterval(() => {
              notifyPush(client_id).then((res) => {
                if (res.code === 0) {
                  if (res.data.length > 0) {
                    const data = res.data
                    data.forEach((item) => {
                      item.content = item.content.replace(/\n/g, "<br>")
                      ElementUI.Notification({
                        title: item.title,
                        message: `<span>${item.content.slice(
                          0,
                          99
                        )}</span><span style="display: ${
                          item.content.length > 100 ? "" : "none"
                        }">... <span style="color: blue;">查看更多>>></span></span>`,
                        position: "bottom-right",
                        dangerouslyUseHTMLString: true,
                        customClass: "notifyClass",
                        iconClass: "el-icon-message-solid",
                        duration: 0,
                        onClick() {
                          this.close()
                          const path = "/wcw"
                          window.location.href = `/wcw/viewBoard/notifyDetail?id=${item.id}`
                          this.activeIndex = path
                        }
                      })
                    })
                  }
                }
              })
            }, 1000 * 60 * 5)
          }
        }
      })
    }
  ],
  afterUnmount: [
    // eslint-disable-next-line no-unused-vars
    (app) => {
      // console.log('afterUnmount', 'color: green;', app.name)
    }
  ]
})
// 此处一定要是code换token成功之后，再进入setDefaultMountApp & start逻辑，否则会出现退出后再登录子系统时会停留在wcw首页而不进入子系统的现象（redirect_uri逻辑未执行）
const codeToTokenSuccess = store.getters.codeToTokenSuccess
if (codeToTokenSuccess === true) {
  setDefaultMountApp("/wcw")
  start()
}
