import { lastestList } from "@/api"

const state = {
  latestRouters: []
}

const mutations = {
  CHANGE_LATEST_ROUTERS: (state, value) => {
    // eslint-disable-next-line no-prototype-builtins
    console.log("CHANGE_LATEST_ROUTERS", value)
    state.latestRouters = value
  }
}

const actions = {
  changeLatestRouters({ commit }, value) {
    commit("CHANGE_LATEST_ROUTERS", value)
  },
  flushLatest({ commit }) {
    lastestList().then((res) => {
      if (res.code === 0) {
        commit("CHANGE_LATEST_ROUTERS", res.data.item)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
