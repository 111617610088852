// import store from './store'
import { getOrigin } from "./utils/getEnv"

const microApps = [
  {
    name: "wcw",
    entry: getOrigin("wcw"),
    activeRule: "/wcw"
  },
  {
    name: "pms",
    entry: getOrigin("pms"),
    activeRule: "/pms"
  },
  {
    // 新增子系统
    name: "crm",
    entry: getOrigin("crm"),
    activeRule: "/crm"
  },
  {
    // 新增子系统
    name: "tms",
    entry: getOrigin("tms"),
    activeRule: "/tms"
  },
  {
    // 新增子系统
    name: "mgs",
    entry: getOrigin("mgs"),
    activeRule: "/mgs"
  },
  {
    // 新增子系统
    name: "ums",
    entry: getOrigin("ums"),
    activeRule: "/ums"
  },
  {
    // 新增子系统
    name: "cms40",
    entry: getOrigin("cms40"),
    activeRule: "/cms40"
  },
  {
    // 新增子系统
    name: "aims",
    entry: getOrigin("aims"),
    activeRule: "/aims"
  },
  {
    // 新增子系统
    name: "datamonitor",
    entry: getOrigin("datamonitor"),
    activeRule: "/datamonitor"
  },
  {
    // 新增子系统
    name: "rms",
    entry: getOrigin("rms"),
    activeRule: "/rms"
  },
  {
    // 新增子系统
    name: "wcc",
    entry: getOrigin("wcc"),
    activeRule: "/wcc"
  },
  {
    // 新增子系统
    name: "csms",
    entry: getOrigin("csms"),
    activeRule: "/csms"
  },
  {
    // 新增子系统
    name: "vlms",
    entry: getOrigin("vlms"),
    activeRule: "/vlms"
  },
  {
    // 新增子系统
    name: "wgp",
    entry: getOrigin("wgp"),
    activeRule: "/wgp"
  },
  {
    // 新增子系统
    name: "fms",
    entry: getOrigin("fms"),
    activeRule: "/fms"
  },
  {
    // 新增子系统
    name: "fsm",
    entry: getOrigin("fsm"),
    activeRule: "/fsm"
  },
  {
    // 新增子系统
    name: "fmp",
    entry: getOrigin("fmp"),
    activeRule: "/fmp"
  },
  {
    // 新增子系统
    name: "fbm",
    entry: getOrigin("fbm"),
    activeRule: "/fbm"
  },
  {
    // 新增子系统
    name: "ffm",
    entry: getOrigin("ffm"),
    activeRule: "/ffm"
  },
  {
    // 新增子系统
    name: "sms",
    entry: getOrigin("sms"),
    activeRule: "/sms"
  },
  {
    // 新增子系统
    name: "pmis",
    entry: getOrigin("pmis"),
    activeRule: "/pmis"
  },
  {
    // 新增子系统
    name: "fom",
    entry: getOrigin("fom"),
    activeRule: "/fom"
  },
  {
    // 新增子系统
    name: "prms",
    entry: getOrigin("prms"),
    activeRule: "/prms"
  },
  {
    // 新增子系统
    name: "cdp",
    entry: getOrigin("cdp"),
    activeRule: "/cdp"
  },
  {
    // 新增子系统
    name: "osm",
    entry: getOrigin("osm"),
    activeRule: "/osm"
  },
  {
    // 新增子系统
    name: "frm",
    entry: getOrigin("frm"),
    activeRule: "/frm"
  },
  {
    // 新增子系统
    name: "etm",
    entry: getOrigin("etm"),
    activeRule: "/etm"
  },
  {
    // 新增子系统
    name: "uvm",
    entry: getOrigin("uvm"),
    activeRule: "/uvm"
  },
  {
    // 新增子系统
    name: "aidams",
    entry: getOrigin("aidams"),
    activeRule: "/aidams"
  },
  {
    // 新增子系统
    name: "adms",
    entry: getOrigin("adms"),
    activeRule: "/adms"
  },
  {
    // 新增子系统
    name: "ftm",
    entry: getOrigin("ftm"),
    activeRule: "/ftm"
  },
  {
    // 新增子系统
    name: "ims",
    entry: getOrigin("ims"),
    activeRule: "/ims"
  }
]

const apps = microApps.map((item) => {
  return {
    ...item,
    container: "#subapp-viewport", // 子应用挂载的div
    props: {
      routerBase: item.activeRule, // 下发基础路由
      fn: {
        show(msg) {
          console.log("two:", msg)
        }
      }
      // getGlobalState: store.getGlobalState // 下发getGlobalState方法
    }
  }
})
export default apps
