import Vue from "vue"
import Vuex from "vuex"
import getters from "./getters"
import latest from "./modules/latest"
import code from "./modules/code"
import feedback from "./modules/feedback"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    latest,
    code,
    feedback
  },
  getters
})

export default store
