/* eslint-disable */
/* 
此处wcw相关token存放两份，主要是为了解决子系统独立域名跳转过来时找不到对应token的问题，因为localStorage可以跨页签共享，sessionStorage不可以跨页签共享
*/

import Cookies from 'js-cookie'

const TOP_DOMAIN = '300624.cn'
const CMS_TOP_DOMAIN = 'wondershare.cn' // cms顶域

// export function getToken (key = 'wcwToken') {
//   return sessionStorage.getItem(key) || localStorage.getItem(key)
// }

// export function setToken (token, key = 'wcwToken') {
//   sessionStorage.setItem(key, token)
//   localStorage.setItem(key, token)
// }

// export function removeToken (key = 'wcwToken') {
//   sessionStorage.removeItem(key)
//   localStorage.removeItem(key)
// }

export function getToken(TokenKey = 'wcwToken') {
  // return Cookies.get(TokenKey)
  const authMode = Cookies.get('wcwAuthMode')
  if (authMode === 'code' && TokenKey === 'wcwToken') {
    return Cookies.get(TokenKey) // code认证方式，wcwToken从当前域获取
  } else { // 其他都从顶域获取
    return Cookies.get(TokenKey, { domain: TOP_DOMAIN })
  }
}

export function setToken (token, TokenKey = 'wcwToken') {
  return Cookies.set(TokenKey, token, { domain: TOP_DOMAIN })
}

export function removeToken (TokenKey = 'wcwToken') {
  return Cookies.remove(TokenKey, { domain: TOP_DOMAIN })
}

export function getCurrentDomainToken(TokenKey = 'wcwToken') {
  return Cookies.get(TokenKey)
}

export function setCurrentDomainToken(token, TokenKey = 'wcwToken') {
  return Cookies.set(TokenKey, token)
}

export function removeCurrentDomainToken(TokenKey = 'wcwToken') {
  return Cookies.remove(TokenKey)
}

export function getTopLocalStorageItem (TokenKey = 'wcwToken') {
  document.domain = TOP_DOMAIN
  return localStorage.getItem(TokenKey)
}

export function setTopLocalStorageItem (token, TokenKey = 'wcwToken') {
  localStorage.setItem(TokenKey, token)
  document.domain = TOP_DOMAIN
}

export function removeTopLocalStorageItem (TokenKey = 'wcwToken') {
  document.domain = TOP_DOMAIN
  localStorage.removeItem(TokenKey)
}

// Cookies.set('name', 'value', { domain: 'subdomain.site.com' });
export function setWsidDomainToken(token) {
  return Cookies.set('wsid', token, { domain: TOP_DOMAIN })
}

export function setWsidCmsDomainToken(token) {
  return Cookies.set('wsid', token, { domain: CMS_TOP_DOMAIN })
}

export function getWsidDomainToken() {
  return Cookies.get('wsid', { domain: TOP_DOMAIN })
}

export function setUserNameDomainToken(token) {
  return Cookies.set('_user_name_', token, { domain: TOP_DOMAIN })
}

export function getUserNameDomainToken() {
  return Cookies.get('_user_name_', { domain: TOP_DOMAIN })
}

export function clearTopDomainToken() {
  // 新增一个子系统，需要在此处添加两个清除顶域cookie的逻辑
  Cookies.remove('wcw_account_session', { domain: TOP_DOMAIN })
  Cookies.remove('Admin-Token', { domain: TOP_DOMAIN })
  Cookies.remove('wsid', { domain: TOP_DOMAIN })
  Cookies.remove('_user_name_', { domain: TOP_DOMAIN })
  Cookies.remove('lang', { domain: TOP_DOMAIN })
  Cookies.remove('wcwToken', { domain: TOP_DOMAIN })
  Cookies.remove('wcwIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('pmsAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('pmsIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('crmAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('crmIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('tmsAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('tmsIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('mgsAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('mgsIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('umsAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('umsIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('cmsAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('cmsIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('fmsAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('fmsIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('fsmAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('fsmIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('fmpAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('fmpIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('fbmAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('fbmIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('ffmAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('ffmIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('smsAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('smsIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('pmisAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('pmisIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('fomAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('fomIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('cdpAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('cdpIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('osmAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('osmIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('frmAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('frmIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('wgpnewAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('wgpnewIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('etmAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('etmIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('uvmAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('uvmIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('ftmAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('ftmIdToken', { domain: TOP_DOMAIN })
  Cookies.remove('imsAuthToken', { domain: TOP_DOMAIN })
  Cookies.remove('imsIdToken', { domain: TOP_DOMAIN })
  //
  Cookies.remove('wcwHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('pmsHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('crmHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('tmsHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('mgsHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('umsHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('cmsHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('fmsHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('fsmHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('fmpHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('fbmHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('ffmHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('smsHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('pmisHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('fomHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('cdpHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('osmHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('frmHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('wgpnewHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('etmHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('uvmHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('ftmHasLogin___', { domain: TOP_DOMAIN })
  Cookies.remove('imsHasLogin___', { domain: TOP_DOMAIN })
}

// cms40设置Admin-Token
export function setCms40AdminToken(token) {
  return Cookies.set('Admin-Token', token, { domain: TOP_DOMAIN })
}

export function setCms40WSAdminToken(token) {
  return Cookies.set('Admin-Token', token, { domain: CMS_TOP_DOMAIN })
}
/* eslint-disable */

// import Cookies from 'js-cookie'

// export function getToken (TokenKey = 'wcwToken') {
//   return Cookies.get(TokenKey)
//   // return Cookies.get(TokenKey, { domain: '300624.cn' })
// }

// export function setToken (token, TokenKey = 'wcwToken') {
//   return Cookies.set(TokenKey, token)
//   // return Cookies.set(TokenKey, token, { domain: '300624.cn' })
// }

// export function removeToken (TokenKey = 'wcwToken') {
//   return Cookies.remove(TokenKey)
//   // return Cookies.remove(TokenKey, { domain: '300624.cn' })
// }
