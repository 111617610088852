import axios from "axios"
import { Message } from "element-ui"
import { getToken } from "@/utils/auth"
import { getCodeAuthLoginUrl } from "@/utils/getEnv"
import store from "@/store"

// 创建一个axios实例
const service = axios.create({
  withCredentials: true,
  baseURL:
    process.env.NODE_ENV === "development" ? process.env.VUE_APP_BASE_API : "",
  // 定义对于给定的HTTP 响应状态码
  validateStatus: function(status) {
    return status >= 200 && status < 500
  },
  // 请求超时
  timeout: 10000
})

// 请求拦截
service.interceptors.request.use(
  (config) => {
    if (
      config.url.endsWith("oauth/authorize") ||
      config.url === "/api/v1/clients"
    ) {
      config.baseURL = process.env.VUE_APP_ACCOUNT_BASE_API
    } else {
      if (getToken()) {
        // 设置授权token
        config.headers.Authorization = getToken()
      }
      if (getToken("wcwIdToken")) {
        // 设置校验token
        config.headers["Id-Token"] = getToken("wcwIdToken")
      }
      if (config.url === "/api/oauth/token") {
        console.log("config.data", config.data)
        const originData = config.data
        if (originData.baseURL) {
          console.log("originData.baseURL", originData.baseURL)
          config.baseURL = originData.baseURL
          config.data = {
            code: originData.code
          }
        }
      }
    }
    return config
  },
  (error) => {
    // 处理错误信息
    console.error(error)
    return Promise.reject(error)
  }
)
// 请求返回拦截
service.interceptors.response.use(
  (response) => {
    const res = response.data
    // 未授权
    if (res.code === 401) {
      const codeToTokenSuccess = store.getters.codeToTokenSuccess
      if (codeToTokenSuccess === true) {
        // 如果code换取token成功，还报401则需要跳转登录页
        window.location.href = getCodeAuthLoginUrl("request401")
      }
      // window.location.href = getLoginUrl()
    } else if (res.code !== 0) {
      Message({
        message: res.msg,
        type: "error",
        duration: 2 * 1000
      })
    }
    return res
  },
  (error) => {
    // 打印错误
    console.error("err " + error)
    Message({
      message: error.message,
      type: "error",
      duration: 2 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
