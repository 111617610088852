import request from "@/utils/request"

// 列表
export const getFeedbackList = (data) => {
  return request.get("/api/v1/feedback", { params: data })
}

// 详情
export const getFeedbackDetail = (id, data) => {
  return request.get(`/api/v1/feedback/${id}`, { params: data })
}

// 添加反馈
export const createFeedback = (data) => {
  return request.post(`/api/v1/feedback`, data)
}

// 处理反馈
export const operateFeedback = (id, data) => {
  return request.put(`/api/v1/feedback/${id}`, data)
}

// 获取oss基本信息
export const getOSSBasicInfo = (params) => {
  return request.get("/api/v1/oss/credential", { params })
}

// 获取oss基本信息
export const getOSSAttachmentUrl = (params) => {
  return request.get("/api/v1/oss/attachment-url", { params })
}
