const state = {
  codeVerify: false,
  codeToTokenSuccess: false
}

const mutations = {
  SET_CODE_VERIFY: (state, value) => {
    // eslint-disable-next-line no-prototype-builtins
    console.log("SET_CODE_VERIFY", value)
    state.codeVerify = value
  },
  SET_CODE_TO_TOKEN_SUCCESS: (state, value) => {
    // eslint-disable-next-line no-prototype-builtins
    console.log("SET_CODE_TO_TOKEN_SUCCESS", value)
    state.codeToTokenSuccess = value
  }
}

const actions = {
  setCodeVerify({ commit }, value) {
    commit("SET_CODE_VERIFY", value)
  },
  setCodeToTokenSuccess({ commit }, value) {
    commit("SET_CODE_TO_TOKEN_SUCCESS", value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
