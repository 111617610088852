<template>
  <el-dialog
    title="意见反馈"
    :visible.sync="mainPopVisible"
    :show-close="false"
    width="40%"
    :destroy-on-close="true"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item
        label="业务系统："
        :label-width="formLabelWidth"
        prop="client_id"
      >
        <el-select
          v-model="form.client_id"
          placeholder="选择系统"
          clearable
          filterable
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in systemList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="反馈类型："
        prop="type"
        :label-width="formLabelWidth"
      >
        <el-radio-group v-model="form.type">
          <el-radio :label="1">产品建议</el-radio>
          <el-radio :label="2">性能体验</el-radio>
          <el-radio :label="3">功能异常</el-radio>
          <el-radio :label="4">其他反馈</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="反馈说明："
        :label-width="formLabelWidth"
        prop="content"
      >
        <el-input v-model="form.content" autocomplete="off" type="textarea" />
      </el-form-item>
      <el-form-item label="上传附件：" :label-width="formLabelWidth">
        <el-upload
          class="upload-demo"
          action="#"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-change="handleChange"
          :before-remove="beforeRemove"
          multiple
          :auto-upload="false"
          :limit="5"
          :on-exceed="handleExceed"
          :file-list="form.attachment"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            文件格式：jpg/png/mp4，且不超过20MB
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" :loading="!allUploaded" @click="onSubmit">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import OSS from "ali-oss"
import { getSystemList } from "@/api/index"
import { createFeedback, getOSSBasicInfo } from "@/api/feedback"
import { isArrayEle } from "@/utils"
import { getCurrentSysIdByPathName } from "@/utils/getEnv"

export default {
  data() {
    return {
      form: {
        client_id: getCurrentSysIdByPathName(),
        type: "",
        content: "",
        attachment: []
      },
      formLabelWidth: "92px",
      systemList: [],
      ossInfo: {},
      fileList: [],
      rules: {
        client_id: [{ required: true, message: "请选择" }],
        type: [{ required: true, message: "请选择" }],
        content: [{ required: true, message: "请输入" }]
      },
      uploadStatus: {},
      allUploaded: true
    }
  },
  computed: {
    ...mapGetters(["mainPopVisible", "ossBasicInfo"])
  },
  watch: {
    mainPopVisible: function(val) {
      if (val) {
        this.getOSSInfo()
      }
    }
  },
  created() {
    console.log("mainPopVisible", this.mainPopVisible)
    this.getSystemOptions()
    console.log("this.$route.path", this.$route.path)
  },
  methods: {
    // 获取系统列表数据
    getSystemOptions() {
      getSystemList({ page: 1, page_size: 100 }).then((res) => {
        this.systemList = res.data.item
      })
    },
    // 获取OSS信息
    getOSSInfo() {
      if (this.ossBasicInfo && this.ossBasicInfo.security_token) {
        this.ossInfo = this.ossBasicInfo
      } else {
        getOSSBasicInfo({ op: "rw" }).then((res) => {
          if (res && res.code === 0 && res.data) {
            this.ossInfo = res.data
            this.$store.dispatch("feedback/SET_OSS_BASIC_INfO", res.data)
          }
        })
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      const newFileList = this.form.attachment.filter((item) => {
        delete this.uploadStatus[item.uid]
        return item.uid !== file.uid
      })
      this.form.attachment = newFileList
      this.allUploaded = true
      console.log("this.form", this.form)
      console.log("this.form.attachment", this.form.attachment)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 5 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      )
    },
    checkAllUploaded() {
      let res = false
      let flag = true
      const attachment = this.form.attachment
      for (let i = 0; i < attachment.length; i++) {
        const uid = attachment[i].uid
        if (!this.uploadStatus[uid]) {
          flag = false
          break
        }
      }
      if (flag) {
        res = true
      }
      return res
    },
    async putObject(client, file, type, name, index) {
      try {
        console.log("putObject file", file)
        console.log("putObject name", name)
        const uid = file.uid
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
        // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
        // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
        const timestamp = Date.parse(new Date()) * 1000 + index * 10
        const fileUrl = this.ossInfo.upload_dir + timestamp + "." + type
        console.log(fileUrl)
        const result = await client.put(fileUrl, file)
        console.log(result)
        if (result && result.url) {
          this.form.attachment[index] = {
            name: name,
            path: fileUrl,
            uid: uid,
            status: "success"
          }
          this.uploadStatus[uid] = true
          const allUploaded = this.checkAllUploaded()
          this.allUploaded = allUploaded
          console.log("uploaded success", name)
        }
        console.log("uploadStatus", this.uploadStatus)
        console.log("this.form", this.form)
        console.log("this.form.attachment", this.form.attachment)
      } catch (e) {
        console.log(e)
        this.handleRemove(file)
        this.allUploaded = true
      }
    },
    handleChange(file, fileList) {
      console.log("file", file)
      console.log("fileList", fileList)
      console.log("uploadStatus", this.uploadStatus)
      if (isArrayEle(fileList)) {
        let flag = true
        for (let i = 0; i < fileList.length; i++) {
          const f = fileList[i]
          if (f.status === "ready" && f.raw.type) {
            // 这种是待上传的文件
            const t = f.raw.type
            if (
              !(t === "image/jpeg" || t === "image/png" || t === "video/mp4")
            ) {
              this.$message.error("只能上传jpg/png/mp4文件")
              flag = false
              break
            }
            const isL = f.size / 1024 / 1024 < 20
            if (!isL) {
              this.$message.error("文件不能超过20M")
              flag = false
              break
            }
          }
        }
        if (!flag) {
          this.form.attachment = this.form.attachment.filter((item) => {
            return item.status === "success"
          })
          return
        }
      }
      this.form.attachment = fileList
      console.log("this.ossInfo", this.ossInfo)
      const client = new OSS({
        accessKeyId: this.ossInfo.access_key_id,
        accessKeySecret: this.ossInfo.access_key_secret,
        bucket: this.ossInfo.bucket,
        region: this.ossInfo.region,
        stsToken: this.ossInfo.security_token,
        uploadDir: this.ossInfo.upload_dir
      })
      console.log("this.ossInfo client", client)
      this.allUploaded = false
      const attachment = this.form.attachment
      const uploadAttachment = [] // 需要上传的文件
      attachment.forEach((item) => {
        if (item.status === "success") {
          // 已经上传成功
          this.uploadStatus[item.uid] = true
        } else {
          this.uploadStatus[item.uid] = false
          uploadAttachment.push(item)
        }
      })
      this.uploadStatus[file.uid] = false
      console.log("this.ossInfo uploadStatus", this.uploadStatus)
      uploadAttachment.forEach(async (item, index) => {
        const name = item.name
        const nameArr = name.split(".")
        const len = nameArr.length
        const fileType = nameArr[len - 1]
        await this.putObject(client, item.raw, fileType, name, index)
      })
    },
    beforeRemove() {
      if (!this.allUploaded) {
        this.$message.error(`正在上传中，不允许删除文件！`)
        return false
      }
    },
    clear() {
      this.form = {
        client_id: getCurrentSysIdByPathName(),
        type: "",
        content: "",
        attachment: []
      }
      this.allUploaded = true
    },
    onCancel() {
      this.clear()
      this.$store.dispatch("feedback/SET_MAIN_POP_VISIBLE", false)
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          console.log("this.form", this.form)
          const value = { ...this.form }
          value.attachment = value.attachment.map((item) => {
            return {
              name: item.name,
              path: item.path
            }
          })
          createFeedback(value).then((res) => {
            if (res.code === 0) {
              this.clear()
              this.$store.dispatch("feedback/SET_MAIN_POP_VISIBLE", false)
              this.$message({
                message: "提交成功",
                type: "success"
              })
              // window.location.href = '/wcw/system/feedback'
            }
          })
        }
      })
    }
  }
}
</script>
