<template>
  <div
    style="
      height: 60px;
      overflow-y: hidden;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      background-color: rgb(48, 65, 86);
    "
  >
    <div style="width: 700px; flex-shrink: 0">
      <el-menu
        ref="wcwTopNav"
        :default-active="activeIndex"
        :unique-opened="true"
        mode="horizontal"
        background-color="#304156"
        text-color="#bfcbd9"
        active-text-color="#409EFF"
        style="border: none"
        menu-trigger="click"
        @select="handleSelect"
      >
        <div class="sidebar-logo-link" @click="toWcw">
          <img
            src="https://images.wondershare.com/wsc-18/assets/icon/logo/wondershare-square-white.png"
            class="sidebar-logo"
          />
          <h1 class="sidebar-title">
            <a
              href="/wcw/viewBoard/home"
              style="color: #fff; text-decoration: none"
            >
              兴云工作台
            </a>
          </h1>
        </div>
        <el-submenu
          index="1"
          class="wcw_topmenu_latest"
          popper-class="submenu-popper-1"
        >
          <template slot="title">
            最近访问
          </template>
          <el-menu-item
            v-for="(item, index) in lastest"
            :key="item.id"
            :index="'1-' + index"
            class="dis_blk"
            @click="handleClickApp(item, 1)"
          >
            {{ item.title + "（" + item.client_name + "）" }}
          </el-menu-item>
        </el-submenu>
        <el-submenu
          index="2"
          class="wcw_topmenu_myapp"
          popper-class="submenu-popper-2"
        >
          <template slot="title">
            我的应用
          </template>
          <el-menu-item
            v-for="(item, index) in appList"
            :key="item.id"
            :index="'1-' + index"
            class="dis_blk"
            @click="handleClickApp(item, 2)"
          >
            {{
              item.title + (item.id === 0 ? "" : "（" + item.client_name + "）")
            }}
          </el-menu-item>
        </el-submenu>
        <el-submenu
          index="3"
          class="wcw_topmenu_system"
          popper-class="submenu-popper-3"
        >
          <template slot="title">
            系统导航
          </template>
          <el-menu-item
            v-for="(item, index) in defaultSysList"
            :key="item.id"
            :index="'3-' + index"
            class="dis_blk"
            @click="handleClickSys(item)"
          >
            {{ item.name }}
          </el-menu-item>
        </el-submenu>
        <el-submenu
          index="4"
          class="wcw_topmenu_helpcenter"
          popper-class="submenu-popper-4"
        >
          <template slot="title">
            帮助中心
          </template>
          <el-menu-item
            v-for="(item, index) in defaultSysList"
            :key="item.id"
            :index="'3-' + index"
            class="dis_blk"
            @click="handleClickFile(item)"
          >
            {{ item.name }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div style="width: 700px; flex-shrink: 0">
      <div class="logOut" @click="logOut">
        <i class="el-icon-switch-button" />
        退出登录
      </div>
      <el-tooltip class="item" effect="dark" :content="name" placement="bottom">
        <div class="name">
          <i class="el-icon-user-solid" />
          {{ name }}
        </div>
      </el-tooltip>
      <div class="callback" @click="callback">
        <i class="el-icon-edit-outline" />
        意见反馈
      </div>
      <div class="callback" @click="toOMS">
        <i class="el-icon-coin" />
        运维管理系统
      </div>
      <div class="callback" @click="toAccountDetail">
        <i class="el-icon-coin" />
        权限明细
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie"
import { mapGetters } from "vuex"
import {
  setWsidDomainToken,
  // clearTopDomainToken,
  setUserNameDomainToken
} from "@/utils/auth"
import {
  lastestList,
  defaultSysList,
  getUserInfo,
  applicationList,
  latestAdd
} from "@/api"
import { getLoginUrl } from "@/utils/getEnv"
import { pwikiReport, gaReport } from "@/utils"

const menuReportDataMap = {
  1: ["visited_app_unfold", "visited_app_fold", "visited_app_"], // 最近访问
  2: ["app_unfold", "app_fold", "app_visit_"], // 我的应用
  3: ["system_unfold", "system_fold", "system_visit_"], // 系统导航
  4: ["helpcenter_unfold", "helpcenter_fold", "helpcenter_visit_"] // 帮助中心
}

export default {
  data() {
    return {
      activeIndex: this.getActive(),
      // 最近访问
      lastest: [],
      // 系统导航
      defaultSysList: [],
      timer: "",
      name: "",
      wsid: null,
      // 我的应用
      appList: [],
      openedMenuIndex: ""
    }
  },
  computed: {
    ...mapGetters(["latestRouters", "codeToTokenSuccess"])
  },
  watch: {
    latestRouters: function() {
      this.$refs["wcwTopNav"].close(this.openedMenuIndex)
      lastestList().then((res) => {
        if (res.code === 0) {
          this.lastest = res.data.item
        }
      })
    },
    codeToTokenSuccess: function() {
      this.mountedFunc()
    }
  },
  mounted() {
    this.mountedFunc()
    this.handleDataReport()
    this.handleMenuItemAutoClose()
  },
  methods: {
    handleMenuItemAutoClose() {
      const _this = this
      for (let i = 1; i < 5; i++) {
        // 鼠标离开popper时，关闭popper
        const popper = document.querySelector(".submenu-popper-" + i)
        popper.onmouseleave = function() {
          _this.$refs["wcwTopNav"].close("" + i)
        }
      }
      window.onmousedown = function(e) {
        const offsetY = e.offsetY
        const offsetX = e.offsetX
        console.log("offsetX, offsetY", offsetX, offsetY)
        if (offsetX > 470) {
          _this.$refs["wcwTopNav"].close(_this.openedMenuIndex)
        }
      }
    },
    handleDataReport() {
      const _this = this
      document
        .querySelector(".wcw_topmenu_latest")
        .addEventListener("click", function(e) {
          console.log("click 最近访问")
          const target = e.target
          if (
            target &&
            target.parentNode &&
            target.parentNode.getAttribute("class") &&
            target.parentNode.getAttribute("class").includes("is-opened")
          ) {
            // 展开
            _this.openedMenuIndex = "1"
            gaReport("wcw10_topbar", "click", "visited_app_unfold")
            pwikiReport("wcw10_topbar", "click", "visited_app_unfold")
          } else {
            // 收起
            gaReport("wcw10_topbar", "click", "visited_app_fold")
            pwikiReport("wcw10_topbar", "click", "visited_app_fold")
          }
        })
      document
        .querySelector(".wcw_topmenu_myapp")
        .addEventListener("click", function(e) {
          console.log("click 我的应用")
          const target = e.target
          if (
            target &&
            target.parentNode &&
            target.parentNode.getAttribute("class") &&
            target.parentNode.getAttribute("class").includes("is-opened")
          ) {
            // 展开
            _this.openedMenuIndex = "2"
            gaReport("wcw10_topbar", "click", "app_unfold")
            pwikiReport("wcw10_topbar", "click", "app_unfold")
          } else {
            // 收起
            gaReport("wcw10_topbar", "click", "app_fold")
            pwikiReport("wcw10_topbar", "click", "app_fold")
          }
        })
      document
        .querySelector(".wcw_topmenu_system")
        .addEventListener("click", function(e) {
          console.log("click 系统导航")
          const target = e.target
          if (
            target &&
            target.parentNode &&
            target.parentNode.getAttribute("class") &&
            target.parentNode.getAttribute("class").includes("is-opened")
          ) {
            // 展开
            _this.openedMenuIndex = "3"
            gaReport("wcw10_topbar", "click", "system_unfold")
            pwikiReport("wcw10_topbar", "click", "system_unfold")
          } else {
            // 收起
            gaReport("wcw10_topbar", "click", "system_fold")
            pwikiReport("wcw10_topbar", "click", "system_fold")
          }
        })
      document
        .querySelector(".wcw_topmenu_helpcenter")
        .addEventListener("click", function(e) {
          console.log("click 帮助中心")
          const target = e.target
          if (
            target &&
            target.parentNode &&
            target.parentNode.getAttribute("class") &&
            target.parentNode.getAttribute("class").includes("is-opened")
          ) {
            // 展开
            _this.openedMenuIndex = "4"
            gaReport("wcw10_topbar", "click", "helpcenter_unfold")
            pwikiReport("wcw10_topbar", "click", "helpcenter_unfold")
          } else {
            // 收起
            gaReport("wcw10_topbar", "click", "helpcenter_fold")
            pwikiReport("wcw10_topbar", "click", "helpcenter_fold")
          }
        })
    },
    mountedFunc() {
      if (this.codeToTokenSuccess !== true) {
        return
      }
      // 用户名
      getUserInfo().then((res) => {
        console.log(res)
        if (res.data) {
          if (res.data.wsid) {
            setWsidDomainToken(res.data.wsid)
            this.wsid = res.data.wsid
          }
          if (res.data.name) {
            setUserNameDomainToken(res.data.name)
            this.name = res.data.name
          }
        }
      })
      this.$store.dispatch("latest/flushLatest")
      // 系统导航
      defaultSysList().then((res) => {
        if (res.code === 0) {
          this.defaultSysList = []
          res.data.forEach((item) => {
            const name = item.name
            const id = item.id
            const lowerName = name.toLowerCase()
            // if (lowerName === "cms40") {
            //   lowerName = "cms";
            // }
            if (
              item.link_target !== 2 &&
              !lowerName.includes("ods") &&
              !lowerName.includes("crm") &&
              lowerName !== "cms40" &&
              id !== 35 &&
              id !== 165
            ) {
              // WCS名称改为CSMS
              // if (lowerName === 'csms') {
              //     item.domain = '/wcs'
              // } else {
              //     item.domain = '/' + lowerName
              // }
              item.domain = "/" + lowerName
            }
            if (item.check) {
              this.defaultSysList.push(item)
            }
          })
          console.log("this.defaultSysList", this.defaultSysList)
        }
      })
      applicationList().then((res) => {
        if (res.code === 0) {
          this.appList = res.data.item
          const arr = []
          const [...appTemp] = this.appList
          let num = 0
          appTemp.map((item, index) => {
            if (arr.includes(item.model_id)) {
              this.appList.splice(index - num, 1)
              num++
            } else {
              arr.push(item.model_id)
            }
          })
          if (this.appList.length > 20) {
            this.appList = this.appList.splice(0, 20)
            this.appList.push({
              id: 0,
              client_name: "WCW",
              title: "查看更多应用...",
              url: "/viewBoard/home"
            })
          } else if (this.appList.length === 0) {
            this.appList.push({
              id: 0,
              client_name: "WCW",
              title: "暂无应用，请添加~",
              url: "/viewBoard/home"
            })
          }
        }
      })
    },
    toWcw() {
      console.log(
        "toWcw this.$store.state.latest.latestRouters",
        this.$store.state.latest.latestRouters
      )
      const path = "/wcw"
      window.location.href = "/wcw/viewBoard/home"
      this.activeIndex = path
    },
    getActive() {
      let res = "/wcw"
      const pathname = window.location.pathname
      if (pathname.includes("/pms")) {
        res = "/pms"
      }
      if (pathname.includes("/crm")) {
        res = "/crm"
      }
      if (pathname.includes("/tms")) {
        res = "/tms"
      }
      if (pathname.includes("/mgs")) {
        res = "/mgs"
      }
      if (pathname.includes("/ums")) {
        res = "/ums"
      }
      if (pathname.includes("/cms40")) {
        res = "/cms40"
      }
      if (pathname.includes("/fms")) {
        res = "/fms"
      }
      if (pathname.includes("/fsm")) {
        res = "/fsm"
      }
      if (pathname.includes("/fmp")) {
        res = "/fmp"
      }
      if (pathname.includes("/fbm")) {
        res = "/fbm"
      }
      if (pathname.includes("/ffm")) {
        res = "/ffm"
      }
      if (pathname.includes("/sms")) {
        res = "/sms"
      }
      if (pathname.includes("/pmis")) {
        res = "/pmis"
      }
      if (pathname.includes("/fom")) {
        res = "/fom"
      }
      if (pathname.includes("/cdp")) {
        res = "/cdp"
      }
      if (pathname.includes("/osm")) {
        res = "/osm"
      }
      if (pathname.includes("/frm")) {
        res = "/frm"
      }
      if (pathname.includes("/etm")) {
        res = "/etm"
      }
      if (pathname.includes("/uvm")) {
        res = "/uvm"
      }
      if (pathname.includes("/adms")) {
        res = "/adms"
      }
      if (pathname.includes("/ftm")) {
        res = "/ftm"
      }
      if (pathname.includes("/ims")) {
        res = "/ims"
      }
      return res
    },
    // 退出登录
    logOut() {
      this.$confirm("确认退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.handleLogOut()
        })
        .catch(() => {})
    },
    clearLocal() {
      // 清空cookie
      const cookies = Cookies.get()
      Object.keys(cookies).map((item) => {
        Cookies.remove(item)
      })
      // 清空所有顶域cookie
      // clearTopDomainToken()
      // 清空localStorage
      localStorage.clear()
      // 清空sessionStorage
      sessionStorage.clear()
    },
    logoutSuccessHandle() {
      this.clearLocal()
      window.location.href = getLoginUrl()
    },
    // 退出登录
    handleLogOut() {
      this.logoutSuccessHandle()
    },
    handleSelect() {
      // console.log(key, keyPath)
    },
    openMenu(key, keyPath) {
      // 展开
      console.log("openMenu", key, keyPath)
    },
    closeMenu(key, keyPath) {
      // 收起
      console.log("closeMenu", key, keyPath)
    },
    handleClick(path) {
      window.location.href = path
      this.activeIndex = path
    },
    // 系统导航
    handleClickSys(item) {
      let path = item.domain
      gaReport("wcw10_topbar", "click", menuReportDataMap[3][2] + item.name)
      pwikiReport("wcw10_topbar", "click", menuReportDataMap[3][2] + item.name)
      if (path !== "/localhost") {
        if (item.name.toLowerCase().includes("ods")) {
          // ods跳转新页面
          window.open(path, "_blank")
        } else if (item.name.toLowerCase() === "crm") {
          path = path
            .replace("dev-wcw", "dev-crm-admin")
            .replace("beta-wcw", "beta-crm-admin")
            .replace("workspace", "crm-admin")
          window.open(path + "/crm/welcome", "_blank")
        } else if (item.id === 35) {
          // 神策（海外）
          window.open(path, "_blank")
        } else if (item.id === 165) {
          // 神策（国内）
          window.open(path, "_blank")
        } else if (item.name.toLowerCase() === "cms40") {
          const openPath = item.domain.replace(".300624.cn", ".wondershare.cn")
          window.open(openPath + "/cms40", "_blank")
        } else {
          if (item.link_target === 2) {
            // 外部链接直接跳转
            window.open(path, "_blank")
          } else {
            window.location.href = path
          }
        }
      }
    },
    handleClickFile(item) {
      gaReport("wcw10_topbar", "click", menuReportDataMap[4][2] + item.name)
      pwikiReport("wcw10_topbar", "click", menuReportDataMap[4][2] + item.name)
      const path = "/wcw"
      window.location.href = "/wcw/viewBoard/help"
      this.activeIndex = path
      sessionStorage.setItem("helpFileSystem", item.id)
    },
    // 我的应用
    handleClickApp(item, key) {
      const clientName = item.client_name
      const appSys = `${item.title}_${clientName}`
      gaReport("wcw10_topbar", "click", menuReportDataMap[key][2] + appSys)
      pwikiReport("wcw10_topbar", "click", menuReportDataMap[key][2] + appSys)
      const sys = clientName.toLowerCase()
      if (sys === "crm") {
        const path = item.domain
          .replace("dev-wcw", "dev-crm-admin")
          .replace("beta-wcw", "beta-crm-admin")
          .replace("workspace", "crm-admin")
        window.open(`${path}${item.url}`, "_blank")
        this.activeIndex = ""
      } else {
        let path = `/${sys}`
        latestAdd({ client_name: clientName, path: item.url })
          .then((res) => {
            if (res.code !== 0) {
              // this.$message.error(res.msg)
            }
            if (sys === "cms40") {
              path = item.domain
                .replace("dev-wcw", "dev-cms40")
                .replace("beta-wcw", "beta-cms40")
                .replace("workspace", "cms40")
                .replace(".300624.cn", ".wondershare.cn")
              window.open(`${path}/cms40${item.url}`, "_blank")
            } else {
              window.location.href = `${path}${item.url}`
            }
            this.activeIndex = path
          })
          .catch((err) => {
            console.error(err)
            if (sys === "cms40") {
              path = item.domain
                .replace("dev-wcw", "dev-cms40")
                .replace("beta-wcw", "beta-cms40")
                .replace("workspace", "cms40")
                .replace(".300624.cn", ".wondershare.cn")
              window.open(`${path}/cms40${item.url}`, "_blank")
            } else {
              window.location.href = `${path}${item.url}`
            }
            this.activeIndex = path
          })
      }
    },
    callback() {
      gaReport("wcw10_topbar", "click", "feedback")
      pwikiReport("wcw10_topbar", "click", "feedback")
      // window.open('http://jira7.300624.cn/projects/WSC/issues')
      this.$store.dispatch("feedback/SET_MAIN_POP_VISIBLE", true)
    },
    toOMS() {
      window.open("https://oms.300624.cn")
    },
    toAccountDetail() {
      window.open(`${location.origin}/wcw/account/expire?my=1`)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.dis_blk {
  display: block;
}
.logOut {
  margin-right: 20px;
  float: right;
  /* position: fixed;
  right: 10px;
  top: 2px; */
  color: #fff;
  z-index: 200000;
  line-height: 60px;
  cursor: pointer;
}
.name {
  margin-right: 20px;
  float: right;
  /* position: fixed;
  right: 120px;
  top: 2px; */
  color: #fff;
  z-index: 200000;
  line-height: 60px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}
.callback {
  margin-right: 20px;
  float: right;
  /* position: fixed;
  right: 220px;
  top: 2px; */
  color: #fff;
  z-index: 200000;
  line-height: 60px;
  cursor: pointer;
}
.logOut:hover,
.callback:hover {
  color: #409eff;
}
.sidebar-logo-link {
  height: 100%;
  margin-top: 5px;
  padding-left: 20px;
  width: 190px;
  float: left;
  cursor: pointer;
}

.sidebar-logo {
  height: 32px;
  vertical-align: middle;
  margin-right: 12px;
}

.sidebar-title {
  display: inline-block;
  margin: 0;
  color: #fff;
  font-weight: 600;
  line-height: 50px;
  font-size: 18px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
}
.el-menu > .el-menu--horizontal {
  border-bottom: none;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #999;
}
.submenu-popper-1,
.submenu-popper-2,
.submenu-popper-3,
.submenu-popper-4 {
  max-height: 700px;
  overflow-y: auto;
}
</style>
