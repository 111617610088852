<template>
  <div id="app">
    <transition name="el-zoom-in-top">
      <div v-if="!isFromSop" class="menuBox">
        <TopMenu />
      </div>
    </transition>
    <!-- <el-button type="primary" class="btnFix" icon="el-icon-menu" circle @click="handleShow" /> -->
    <MainPop />
    <div id="subapp-viewport" class="subapp-viewport" />
  </div>
</template>

<script>
import NProgress from "nprogress"
import { mapGetters } from "vuex"
import TopMenu from "./components/TopMenu.vue"
import MainPop from "./feedback/MainPop"
import { getWsidDomainToken, getUserNameDomainToken } from "@/utils/auth"
import { GetQueryString } from "@/utils"
import { latestAdd } from "@/api"
import store from "@/store"

export default {
  name: "App",
  components: {
    TopMenu,
    MainPop
  },
  data() {
    return {
      show: true,
      isFromSop: GetQueryString("isFromSop") == 1
    }
  },
  computed: {
    ...mapGetters(["codeToTokenSuccess"])
  },
  watch: {
    $route: {
      handler(val, oldval) {
        console.log("APP current path", val) // 新路由信息
        console.log("APP old path", oldval) // 老路由信息
        const fullPath = val.fullPath
        const clientName = fullPath.split("/")[1]
        const path = fullPath.split("/" + clientName)[1]
        this.pwiki()
        if (this.codeToTokenSuccess) {
          latestAdd({ client_name: clientName.toUpperCase(), path: path }).then(
            (res) => {
              if (res.code !== 0) {
                // this.$message.error(res.msg)
              } else {
                store.dispatch("latest/flushLatest")
              }
            }
          )
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  created() {
    // 注释掉这行，这行会导致wcw token覆盖
    if (this.$route.query.access_token) {
      // setToken(`${this.$route.query.token_type} ${this.$route.query.access_token}`)
      // setToken(this.$route.query.id_token, 'wcwIdToken')
    }
    /*
        gtag('event', <action>, {
            'event_category': <category>,
            'event_label': <label>,
            'value': <value>
        })
        */
    // 加入gtag埋点事件收集
    const wsid = getWsidDomainToken()
    if (wsid && window.gtag) {
      // eslint-disable-next-line
            gtag('event', 'Onload', {
        event_category: "WCW10_Core_Metric",
        event_label: wsid
      })
    }
    NProgress.start()
  },
  mounted() {
    NProgress.done()
  },
  methods: {
    pwiki() {
      var title = document.title
      var _realname = getUserNameDomainToken()
      var u =
        (document.location.protocol == "https:" ? "https" : "http") +
        "://piwik.wondershare.cn/"
      /* eslint-disable */
            _paq.push(['setTrackerUrl', u + 'piwik.php'])
            _paq.push(['setSiteId', '4'])
            _paq.push(['setUserId', _realname])
            _paq.push(['setDocumentTitle', title])
            _paq.push(['trackPageView'])
        },
        handleShow() {
            this.show = !this.show
        },
        bindCurrent(type) {

        },
        listenRouterChange() {
            const _wr = function(type) {
                const orig = history[type]
                return function() {
                    const rv = orig.apply(this, arguments)
                    const e = new Event(type)
                    e.arguments = arguments
                    window.dispatchEvent(e)
                    return rv
                }
            }
            history.pushState = _wr('pushState')

            window.addEventListener('pushState', this.bindCurrent)
            window.addEventListener('popstate', this.bindCurrent)

            this.$once('hook:beforeDestroy', () => {
                window.removeEventListener('pushState', this.bindCurrent)
                window.removeEventListener('popstate', this.bindCurrent)
            })
        }
    }
}
</script>

<style>
html,body{
    margin: 0;
    padding: 0;
}
body {
  overflow: hidden;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    overflow-y: hidden;
}
#subapp-viewport {
    height: 100%;
    overflow-y: auto;
}
.menuBox{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: auto;
    z-index: 100;
    /* z-index: 10000; */
    -moz-box-shadow: 2px 2px 15px #888888; /* 老的 Firefox */
    box-shadow: 2px 2px 15px #888888;
}
.btnFix{
    position: fixed;
    top: 10%;
    right: 50px;
    z-index: 10000;
}
.app-wrapper {
    margin-top: 60px;
}
#app .sidebar-container {
    margin-top: 60px;
}
.main-container .site-menu {
    height: auto !important;
}
.ant-layout-has-sider {
  margin-top: 60px;
}
.ant-pro-basicLayout {
  height: 100vh;
  overflow-y: auto;
  background: #304156;
}
.ant-layout {
  height: fit-content !important;
}
/* .ant-pro-sider-menu-sider {
  min-height: 99999px !important;
} */
.notifyClass {
  color: #409eff;
  cursor: pointer;
  width: 30% !important;
  word-wrap: break-word;
  word-break: break-all;
}
.el-notification__title {
  margin: 0;
  line-height: 25px;
}
.el-notification__icon {
  font-size: 30px
}
</style>
